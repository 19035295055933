.message {
	padding: 10px;
	width: 100%;
	width: fit-content;
	clear: both;
	// background-color: blanchedalmond;
	// float: right;
	margin-top: 15px ;
	border-radius: 10px;
	p{
	 color:black;
		font-size: 16px;
		font-weight: 600;

	}
	.time{
		font-size: 9px;
    color: gray;
    text-align: end;
    line-height: 0px;
    margin-top: -10px;
	}
}

.heading-right-message{
	float: right;
}

.my-message{
	background-color: blanchedalmond;
	float: left;

}

.to-message{
	float: right;
	background-color: rgb(14, 233, 215);
}